<template>
  <Modal ref="modal" v-bind="$attrs" v-on="$listeners">
    <div class="mc">
      <div class="mc-body">
        <slot></slot>
      </div>
      <div class="mc-actions">
        <FormButton @click="onYes" class="mr-4">Yes</FormButton>
        <FormButton @click="onNo" class="mr-2">No</FormButton>
      </div>
    </div>
  </Modal>
</template>

<script>

import Modal from './Modal'
import AdminForms from '@/mixins/AdminForms'

export default {

  name: 'modal-confirm',

  inheritAttrs: false,

  mixins: [AdminForms],

  components: {
    Modal
  },

  methods: {

    onYes () {
      this.$emit('yes')
      this.$emit('closed')
    },

    onNo () {
      this.$emit('no')
      this.$emit('closed')
    }
  }

}
</script>

<style lang="postcss" scoped>

.mc-body {
  @apply mb-8
}

.mc-actions {
  @apply flex w-full
}

.mc-actions /deep/ button {
  @apply w-full
}

</style>
