<template>
  <!-- <VueDropzone ref="vueDropzone" id="dropzone"
    :options="dropzoneOptionsComputed"
    :awss3="awss3"
    opzone-success="uploadSuccess"
    @vdropzone-s3-upload-error="s3UploadError" /> -->
  <VueDropzone ref="vueDropzone" id="dropzone"
    :options="dropzoneOptionsComputed"
    @vdropzone-success="uploadSuccess"
    @vdropzone-error="uploadError" />
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {

  name: 'Uploader',

  components: {
    VueDropzone: vue2Dropzone
  },

  props: {

    slug: {
      type: String,
      required: true
    },

    cover: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      dropzoneOptions: {
        url: 'https://chrisandbrittanyjenkins.com/sign/upload.php',
        dictDefaultMessage: 'Click or drop images here to add to album',
        thumbnailWidth: 180,
        renameFile: (file) => {
          return Date.now() + Math.floor(Math.random()) + '_' + file.name
        }
      }
    }
  },

  computed: {
    awss3 () {
      return {
        ...this.awss3default,
        params: {
          slug: this.slug,
          cover: this.cover ? 'yes' : 'no'
        }
      }
    },

    dropzoneOptionsComputed () {
      const opts = {
        ...this.dropzoneOptions,
        url: window.location.origin + '/sign/upload.php',
        params: { slug: this.slug, cover: this.cover ? 'yes' : 'no' }
      }
      return this.cover ? { ...opts, dictDefaultMessage: 'Click or drop cover image here' } : opts
    }
  },

  methods: {
    uploadSuccess (file) {
      console.log('uploadSuccess', file)
      this.$refs.vueDropzone.removeFile(file)
      this.$emit('uploaded', file.upload.filename)
    },

    uploadError (file, errorMessage) {
      alert(errorMessage)
    }
  }
}

</script>

<style lang="postcss" scoped>
  .dropzone {
    @apply bg-transparent flex items-center flex-wrap justify-center overflow-y-auto
  }

  .dropzone /deep/ .dz-preview {
    @apply rounded overflow-hidden
  }

  .dropzone /deep/ .dz-preview .dz-details {
    @apply bg-gradient-to-b from-indigo-500 to-indigo-600
  }
</style>
