<template>
  <transition name="modal" v-on:after-enter="onAfterModalEnter" v-on:after-leave="onAfterModalLeave" appear>
    <div v-if="modal" class="modal" key="modal">
      <transition name="modal-body" v-on:after-leave="onAfterBodyLeave" appear>
        <div v-if="body" class="modal-body" key="body">
          <slot v-bind:close="close">
            <div>I am a modal <button @click="close">Close</button></div>
          </slot>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {

  name: 'modal',

  data () {
    return {
      modal: true,
      body: false
    }
  },

  methods: {

    close () {
      this.body = false
    },

    onAfterModalEnter () {
      this.body = true
    },

    onAfterBodyLeave () {
      this.modal = false
    },

    onAfterModalLeave () {
      this.$emit('closed')
    }
  }
}
</script>

<style lang="postcss" scoped>

  .modal {
    @apply absolute inset-0 bg-black bg-opacity-50 z-20 flex items-center justify-center
  }

  .modal-enter-active,
  .modal-leave-active {
    transition: opacity 0.18s linear;
  }

  .modal-enter,
  .modal-leave-to {
    opacity: 0;
  }

  .modal-body {
    @apply bg-gray-800 bg-opacity-95 p-8 rounded
  }

  .modal-body-enter-active,
  .modal-body-leave-active {
    transition: all 0.18s linear;
  }

  .modal-body-enter, .modal-body-leave-to {
    opacity: 0.6;
    transform: scale(0.63);
  }

</style>
