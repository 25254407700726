<template>

  <form class="edit-album" :class="{ dirty: !synced && action === 'unsaved' }" name="edit">

    <div class="edit-actions">
      <div class="edit-actions-inner">
        <div class="edit-actions-buttons">
          <FormButton @click="onPublish" class="small mr-3">Publish</FormButton>
          <FormButton @click="setEditAlbum" class="red small">Cancel</FormButton>
        </div>
      </div>
    </div>

    <div class="edit-main">

      <div class="edit-left">
        <FormInput v-if="!currentAlbum" label="Slug" prefix="/" v-model="editAlbum.slug" />
        <FormInput v-else label="Slug" prefix="/" v-model="editAlbum.slug" :disabled="true">
          <template slot="label-right">
            <a :href="`/albums/${editAlbum.slug}`" target="_blank">View Album &#9657;</a>
          </template>
        </FormInput>
        <FormInput label="Title" v-model="editAlbum.title" />
        <FormInput label="Date" v-model="editAlbum.date" />

        <FormGroup v-if="editAlbum.slug" label="Cover">

          <transition name="fade" mode="out-in" appear>

            <div v-if="cover && !editCover" key="cover">
              <img :src="cover" class="rounded" />
              <FormButton class="mt-4" @click="editCover = true">Change</FormButton>
            </div>

            <div v-else key="editCover">
              <Uploader :cover="true" :slug="uploaderSlug" @uploaded="onUploadedCover" />
              <FormButton v-if="cover" class="mt-4 red" @click="editCover = false">Cancel</FormButton>
            </div>

          </transition>

        </FormGroup>

        <FormGroup v-if="currentAlbum" class="mb-24" :label="`Images (${this.numImages})`">
          <Uploader :slug="uploaderSlug" @uploaded="onUploadedImage" />
        </FormGroup>
      </div>

      <div id="imageScroller" class="edit-right">
        <Images ref="images"
          :slug="editAlbum.slug"
          :images="editAlbum.images"
          @updateOrder="onUpdateImageOrder"
          @removeImage="onRemoveImage" />

        <template v-if="!currentAlbum">
          <div class="px-7">
            <div class="text-2xl pt-7 pb-4 opacity-90">
              New album
            </div>
            <ul class="list-disc pl-6 opacity-70">
              <li>
                Once you publish the slug for the URI path it cannot be changed
              </li>
              <li>
                You can add images to the album after you publish the initial required fields
              </li>
            </ul>
          </div>
        </template>
      </div>
    </div>

    <ModalConfirm v-if="modal" @closed="modal = false">
      Remove image from album?
    </ModalConfirm>

    <Modal v-if="action === 'saving'">Saving...</Modal>

  </form>

</template>

<script>

import { mapActions, mapState } from 'vuex'

import Images from '@/components/Images'
import AdminForms from '@/mixins/AdminForms'
import Uploader from '@/components/admin/Uploader'
import Modal from '@/components/admin/Modal'
import ModalConfirm from '@/components/admin/ModalConfirm'

import UPDATE_ALBUM from '@/graphql/UpdateAlbum.gql'
import TEST_UPDATE_ALBUM from '@/graphql/development/TestUpdateAlbum.gql'

import INSERT_ALBUM from '@/graphql/InsertAlbum.gql'
import TEST_INSERT_ALBUM from '@/graphql/development/TestInsertAlbum.gql'

import { ibUrl } from '@/helpers'

const arrMove = require('array-move')
const deepEqual = require('deep-equal')
const ObjectID = require('bson-objectid')

export default {

  name: 'album',

  mixins: [AdminForms],

  components: {
    Images,
    Uploader,
    Modal,
    ModalConfirm
  },

  props: ['currentAlbum'],

  data () {
    return {
      editAlbum: {},
      action: 'unsaved',
      modal: false,
      editCover: false
    }
  },

  computed: {

    ...mapState(['albums']),

    numImages () {
      return this.editAlbum.images ? this.editAlbum.images.length : 0
    },

    synced () {
      return !this.editAlbum.slug || (!this.currentAlbum && !this.validateMinimal) || !this.validateMinimal || deepEqual(this.editAlbum, this.currentAlbum)
    },

    validateMinimal () {
      const o = this.editAlbum
      return o.slug && o.title && o.date && o.cover
    },

    cover () {
      return (this.editAlbum && this.editAlbum.cover) ? ibUrl('cover:center/900x600', this.editAlbum.slug, this.editAlbum.cover, true) : null
    },

    uploaderSlug () {
      return this.currentAlbum ? this.currentAlbum.slug : this.editAlbum.slug
    }
  },

  methods: {

    ...mapActions(['publishAlbum']),

    setEditAlbum () {
      this.action = 'unsaved'
      this.editAlbum = { ...this.currentAlbum }
      this.$nextTick(() => {
        if (this.$refs.images) this.$refs.images.resetActive()
      })
    },

    onUpdateImageOrder ({ image, rel }) {
      const from = this.editAlbum.images.findIndex(o => o === image.id)
      const to = from + rel
      this.editAlbum = {
        ...this.editAlbum,
        images: arrMove(this.editAlbum.images, from, to)
      }
    },

    onRemoveImage (i) {
      const copy = [...this.editAlbum.images]
      copy.splice(i, 1)
      this.editAlbum = {
        ...this.editAlbum,
        images: copy
      }
    },

    onUploadedImage (name) {
      this.$refs.images.resetActive()
      const copy = this.editAlbum.images ? [...this.editAlbum.images] : []
      copy.push(name)
      this.editAlbum = {
        ...this.editAlbum,
        images: copy
      }
    },

    onUploadedCover (name) {
      this.editAlbum = {
        ...this.editAlbum,
        cover: name
      }
      this.editCover = false
    },

    onPublish () {
      if (this.synced) return
      this.action = 'saving'
      const mutation = this.currentAlbum
        ? (process.env.NODE_ENV === 'development' ? TEST_UPDATE_ALBUM : UPDATE_ALBUM)
        : (process.env.NODE_ENV === 'development' ? TEST_INSERT_ALBUM : INSERT_ALBUM)
      this.doMutation(mutation)
    },

    doMutation (mutation) {
      const variables = {
        ...this.editAlbum,
        order: this.currentAlbum ? this.currentAlbum.order : this.albums.data.albums.length + 1,
        id: this.currentAlbum ? this.currentAlbum.id : ObjectID()
      }
      this.$apollo.mutate({
        mutation,
        variables,
        update: (store, { data }) => {
          this.action = 'unsaved'
          this.publishAlbum(this.editAlbum)
          const to = `/admin/portal/edit/${this.editAlbum.slug}`
          if (this.$route.path !== to) this.$router.push(to)
        }
      })
    }
  },

  watch: {
    currentAlbum (newVal, oldVal) {
      this.setEditAlbum()
    }
  },

  mounted () {
    if (this.currentAlbum) {
      this.setEditAlbum()
    }
  }
}
</script>

<style lang="postcss" scoped>

  .edit-album {
    @apply w-full h-full relative flex flex-col overflow-hidden
  }

  .edit-actions {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.8);
    transition: top 0.67s linear;
    top: -68px;
    @apply relative z-50 overflow-hidden
  }

  .edit-actions-inner {
    @apply p-2 w-full h-full
  }

  .edit-actions-buttons {
    @apply p-3 flex items-center justify-end bg-gradient-to-r from-transparent via-transparent to-black bg-opacity-10 rounded
  }

  .edit-main {
    transition: margin 0.67s linear;
    grid-template-columns: 1fr 2fr;
    margin-top: -68px;
    @apply flex-1 grid relative z-10 w-full overflow-hidden
  }

  .edit-album.dirty .edit-actions {
    top: 0;
  }

  .edit-album.dirty .edit-main {
    margin-top: 0;
  }

  .edit-left {
    box-shadow: 4px 0 8px 0 rgba(0,0,0,0.8);
    @apply bg-gray-700 bg-opacity-10 flex flex-col overflow-y-auto p-6
  }

  .edit-right {
    box-shadow: 4px -4px 8px 0 rgba(0,0,0,0.8);
    @apply overflow-y-auto
  }

  .cover {
    @apply rounded w-full
  },

  .album-col {
    @apply flex-1 mr-2
  }

  .album-col:last-child {
    @apply mr-0 ml-2
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

</style>
