<template>
  <div class="images">
    <transition-group name="image-list" tag="ul">
      <li class="image-item" v-for="(image, i) in imageItems" :key="image.id" :id="`image-${i}`" :ref="image.id">
        <button type="button" class="image-btn" @click="setActive(image, i)" :class="{ active: activeImage ? activeImage.image.id === image.id : false }">
          <img :src="image.url" class="image" />
        </button>

        <div class="image-actions">
          <FormButton @click="moveImage(image, -1)" class="smalls"><span class="move">&#x276E;</span></FormButton>
          <FormButton @click="moveImage(image, 1)" class="smalls"><span class="move">&#x276F;</span></FormButton>
          <FormButton @click="removeImage(i)" class="red round small mt-6">&#x2715;</FormButton>
        </div>

      </li>
    </transition-group>
  </div>
</template>

<script>

// import BImg from '@/components/BImg'
import FormButton from '@/components/admin/FormButton'

import { ibUrl } from '@/helpers'

export default {

  name: 'images',

  components: {
    FormButton
  },

  data () {
    return {
      activeImage: null,
      activeRel: 0,
      scrollOnUpdate: true
    }
  },

  props: ['slug', 'images'],

  computed: {
    imageItems () {
      return this.images ? this.images.map(img => {
        return { id: img, url: ibUrl('width/400', this.slug, img) }
      }) : []
    }
  },

  methods: {

    resetActive () {
      this.activeImage = null
    },

    setActive (image, i) {
      this.activeImage = { image, i }
      this.scrollToImage(i)
    },

    isActive (image) {
      return image === this.activeImage.image
    },

    scrollToImage (index) {
      this.$scrollTo(`#image-${index}`, 600, { container: '#imageScroller', offset: -100 })
    },

    moveImage (image, rel) {
      this.activeRel = rel
      this.$emit('updateOrder', { image, rel })
    },

    removeImage (i) {
      this.scrollOnUpdate = false
      this.activeImage = null
      this.$emit('removeImage', i)
    }
  },

  watch: {
    images (newVal) {
      if (newVal) {
        this.$nextTick(() => {
          const activeIndex = this.activeImage ? (newVal.findIndex(o => o === this.activeImage.image.id) || 0) : newVal.length - 1
          if (this.scrollOnUpdate) this.scrollToImage(activeIndex)
          this.scrollOnUpdate = true
        })
      }
    }
  }
}
</script>

<style lang="postcss" scoped>

  .image-list-move {
    transition: transform 1s;
  }

  .image-item {
    @apply m-3 rounded flex
  }

  .image-btn {
    transition: all 0.64s ease;
    @apply rounded overflow-hidden block p-3
  }

  .image-btn:hover {
    @apply bg-gray-600 bg-opacity-20
  }

  .image {
    transition: filter 0.64s ease;
    filter: grayscale(100%);
    width: 400px;
    @apply rounded relative z-30
  }

  .image-btn:hover .image {
    filter: grayscale(0%);
  }

  .move {
    transform: rotate(90deg);
    @apply inline-block relative z-40
  }

  .image-actions {
    transition: opacity 0.34s linear;
    @apply flex flex-col items-center justify-center ml-6 opacity-0
  }

  .image-actions button {
    @apply mb-1
  }

  .image-btn.active .image {
    filter: grayscale(0%);
  }

  .image-btn.active + .image-actions {
    @apply opacity-100
  }

</style>
